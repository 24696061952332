import { http } from "@/utils/http.js"
import { Toast } from 'vant';
import cos from "@/api/common/cos";
import { uploadConfig } from "@/utils/setting.js"
import { getUserInfo } from "@/utils/storage.js"
import Compressor from 'compressorjs';
const userInfo = getUserInfo()
let fileParentPath = "other"
if (typeof userInfo.phone != "undefined") {
	fileParentPath = userInfo.phone
}
const showFailToast = Toast.fail
const showLoadingToast = Toast.loading
/**
 * 获取专项题库分类信息
 * @return Array|bool
 */
export const getProfessionalQuestion = async () => {
	const ret = await http.get("/api/Question/getProfessionalQuestion")

	if (ret.code == 1) {
		return ret.data
	} else {
		showFailToast(ret.msg)
		return false
	}
}
/**
 * 获取专项题库信息
 * @params 请求参数
 * @return object|bool
 */
export const getQuestionList = async (params) => {
	const ret = await http.get("/api/Question/getQuestionList", { params })
	if (ret.code == 1) {
		return ret.data
	} else {
		showFailToast(ret.msg)
		return false
	}
}
/**
 * 获取所有分类
 * @return Array
 */
export const getAllTypes = async () => {
	const ret = await http.get("/api/Question/getAllTypes")
	if (ret.code == 1) {
		return ret.data
	} else {
		showFailToast(ret.msg)
		return false
	}
}
/**
 * 获取装箱题库的统计信息
 * @return object
 */
export const getStatistics = async () => {
	const ret = await http.get("/api/Question/getStatistics")
	if (ret.code == 1) {
		return ret.data
	} else {
		showFailToast(ret.msg)
		return false
	}
}

/**
 * 获取做题配置信息
 * @return object
 */
export const getConfig = async () => {
	const ret = await http.get("/api/Question/getConfig")
	if (ret.code == 1) {
		return ret.data
	} else {
		showFailToast(ret.msg)
		return false
	}
}

/**
 * 更新配置信息
 * @method post
 * @return bool
 */
export const updateConfig = async (data) => {
	const ret = await http.post("/api/Question/updateConfig", data)
	if (ret.code == 1) {
		return true
	} else {
		showFailToast(ret.msg)
		return false
	}
}
/**
 * 获取题目信息
 */
export const getQuestionDetail = async (params) => {
	const ret = await http.get("/api/Question/getQuestionDetail", { params })
	if (ret.code == 1) {
		return ret.data
	} else {
		showFailToast(ret.msg)
		return false
	}
}
/**
 * 添加反馈
 * @param data obejct
 * @return object
 */
export const addFeedBack = async (data) => {
	const ret = await http.post("/api/Question/addFeedBack", data)
	if (ret.code == 1) {
		return ret.data
	} else {
		showFailToast(ret.msg)
		return false
	}
}

/**
 * 点赞/取消点赞
 * @param data obejct
 * @return object
 */
export const starToggle = async (data) => {
	const ret = await http.post("/api/Question/starToggle", data)
	if (ret.code == 1) {
		return ret
	} else {
		showFailToast(ret.msg)
		return false
	}
}
/**
 * 获取我的答题结果
 * @method get
 * @param params
 * @return object
 */
export const getQuestionAnswer = async (params) => {
	const ret = await http.get("/api/Question/getUserQuestionAnswer", { params })
	if (ret.code == 1) {
		return ret.data
	} else {
		showFailToast(ret.msg)
		return false
	}
}
/**
 * 更新答题结果
 * @method post
 * @param data
 * @return object
 */
export const updateAnswer = async (data) => {
	const ret = await http.post("/api/Question/addQuestionAnswer", data)
	if (ret.code == 1) {
		showFailToast(ret.msg)
		return ret.data
	} else {
		showFailToast(ret.msg)
		return false
	}
}

/**
 * 获取答案
 * @method get
 * @param params
 * @return object
 */
export const getSystemAnswer = async (params) => {
	const ret = await http.get("/api/Question/getSystemAnswer", { params })
	if (ret.code == 1) {
		return ret.data
	} else {
		showFailToast(ret.msg)
		return false
	}
}
/**
 * 获取做题的进度
 * @method get
 * @param params 
 * @return object
 */
export const getProgress = async (params) => {
	const ret = await http.get("/api/Question/getProgress", { params })
	if (ret.code == 1) {
		return ret.data
	} else {
		showFailToast(ret.msg)
		return false
	}
}

/**
 * 获取题目列表
 */
export const getQuestionListByPqid = async (params) => {
	const ret = await http.get("/api/Question/getquestionByProfessionId", { params })
	if (ret.code == 1) {
		return ret.data
	} else {
		showFailToast(ret.msg)
		return false
	}
}

/**
 * 添加答题记录
 */
export const addAnswerRecords = async (data) => {
	const ret = await http.post("/api/Question/addAnswerRecords", data)
	if (ret.code == 1) {
		return ret.data
	} else {
		showFailToast(ret.msg)
		return false
	}
}

/**
 * 添加答题报告数据
 */
export const addAnswerAndReportData = async (data) => {
	const ret = await http.post("/api/Question/addAnswerAndReportData", data)
	if (ret.code == 1) {
		return ret.data
	} else {
		showFailToast(ret.msg)
		return false
	}
}
/**
 * 获取答题报告的数据
 */
export const getReportData = async (params) => {
	const ret = await http.get("/api/Question/getReportData", { params })
	if (ret.code == 1) {
		return ret.data
	} else {
		showFailToast(ret.msg)
		return false
	}
}

//文件上传
export const uploadFileTmp = async (formData) => {
	const headers = {
		'Content-Type': 'multipart/form-data'
	}
	const ret = await http.post("/api/common/upload", formData, headers)
	if (ret.code == 1) {
		return ret.data
	} else {
		showFailToast(ret.msg)
		return false
	}
}

export const uploadFile = async (formData) => {
	const file = formData.get('file')

	new Compressor(file, {
		quality: 0.6, // 质量设置，0 到 1 之间，越小压缩率越高
		success(result) {
			// 处理压缩后的文件
			console.log(result);
			// 你可以在这里上传文件或进行其他处理
		},
		error(err) {
			console.error(err.message);
		},
	});
	return new Promise((resolve, reject) => {
		cos.putObject({
			Bucket: uploadConfig.Bucket, /* 填写自己的 bucket，必须字段 */
			Region: uploadConfig.Region,     /* 存储桶所在地域，必须字段 */
			Key: fileParentPath + "/" + file.name,/* 存储在桶里的对象键（例如:1.jpg，a/b/test.txt，图片.jpg）支持中文，必须字段 */
			Body: file, /* 上传文件路径，必须字段 */
			SliceSize: uploadConfig.maxSingleSize,     /* 触发分块上传的阈值，超过5MB使用分块上传，小于5MB使用简单上传。可自行设置，非必须 */
		}, function (err, data) {
			if (err) {
				reject(err)
			} else {
				resolve({
					fullurl: "https://" + data.Location.replace(/https?:\/\//g, '')
				})
			}
		});
	})
}

/**
 * 作业批改
 */
export const getWaitForCorrection = async (params) => {
	const ret = await http.get("/api/Question/getWaitForCorrection", { params })
	return ret;
}

/**
 * 重置批改锁
 */
export const resetCorrectionlock = async (params) => {
	const ret = await http.get("/api/Question/resetCorrectionlock", { params })
	return ret;
}
/**
 * 更新批改结果
 * @returns mix
 */
export const updateCorrectionResult = async (data) => {
	const ret = await http.post(`/api/Question/updateCorrectionResult`, data)
	if (ret.code == 1) {
		return ret.data
	} else {
		showFailToast(ret.msg)
		return false
	}
}
/**
 * 
 * @param {} data 
 * @returns 
 */
export const getCorrectionResultInfo = async (params) => {
	const ret = await http.get(`/api/Question/getCorrectionResultInfo`, { params })
	return ret
}
/**
 *  模板消息通知批改
 */
export const sendTaskSubmitTemplateMessage = async (params) => {
	const ret = await http.get("/api/Question/sendTaskSubmitTemplateMessage", { params })
	if (ret.code == 1) {
		return ret.data
	} else {
		showFailToast(ret.msg)
		return false
	}
}
/**
 * 任务题库提交任务结果
 * @param {} data 
 * @returns 
 */
export const taskOtherRecord = async (data) => {
	const ret = await uni.$u.http.post("/api/task/taskOtherRecord", data)
	if (ret.code == 1) {
		return ret.data
	} else {
		uni.tip(ret.msg)
		return false
	}
}
/**
 *  模板消息通知批改
 */
export const sendCorrectionDoneRemind = async (params) => {
	const ret = await http.get("/api/Question/sendCorrectionDoneRemind", { params })
	if (ret.code == 1) {
		return ret
	} else {
		showFailToast(ret.msg)
		return false
	}
}