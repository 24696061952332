import Router from 'vue-router';
import Vue from 'vue';
Vue.use(Router);
import mathSel from '@/pages/math_sel.vue'
import mathAnswer from '@/pages/math_answer.vue'
import mathSelfTest from '@/pages/math_self_test.vue'
import mathAnswerAnalysis from '@/pages/math_answer_analysis.vue'
import answerSheet from '@/pages/answer_sheet.vue'
import examinationReport from "@/pages/examination_report.vue"
import mathSelAnswer from '@/pages/math_sel_answer.vue'
import mathFill from "@/pages/math_fill.vue"
import mathShort from "@/pages/math_short.vue"
import mathFillAnswer from "@/pages/math_fill_answer.vue"
import mathShortAnswer from "@/pages/math_short_answer.vue"
import correction from "@/pages/correction"
import test from "@/pages/test.vue"
import empty from "@/pages/empty"
const routes = [
  {
    path: '/',
    redirect: '/empty',
  },
  {
    path: '/test',
    component: test
  },
  { path: '/mathsel', component: mathSel, name: 'mathsel' },
  { path: '/math_answer', component: mathAnswer, name: 'mathanswer' },
  { path: '/math_self_test', component: mathSelfTest, name: 'mathselftest' },
  {
    path: '/math_answer_analysis',
    component: mathAnswerAnalysis,
    name: 'mathansweranalysis',
  },
  {
    path: '/answer_sheet',
    component: answerSheet,
    name: 'answerSheet',
  },
  { path: '/examination_report/:report_id/:hideBtn', component: examinationReport, name: 'examinationReport' },
  { path: '/math_sel_answer', component: mathSelAnswer, name: 'mathSelAnswer' },
  { path: '/math_fill', component: mathFill, name: 'mathFill' },
  { path: '/math_short', component: mathShort, name: 'mathShort' },
  { path: '/math_fill_answer', component: mathFillAnswer, name: 'mathFillAnswer' },
  { path: '/math_short_answer', component: mathShortAnswer, name: 'mathShortAnswer' },
  { path: '/correction/:report_id/:unionid', component: correction, name: 'correction' },
  { path: '/empty', component: empty, name: 'empty' },
]
const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
});
export default router
