<template>
    <div class="container">
        <div class="type">填空题</div>
        <div class="question-title" v-html="title"></div>
        <div class="answer">
            <h2>学生的答案</h2>
            <div class="input">
                <van-field v-model="stuAnswer.answer" readonly rows="1" size="large" autosize type="textarea" placeholder="请输入答案" />
            </div>
        </div>

        <div class="answer">
            <h2>自评分数</h2>
            <div class="input-score" style="margin-bottom: 12px;">
                <van-field v-model="answer.selfScore" readonly placeholder="请输入分数" />
            </div>

            <div id="canvas-container" ref="canvasContainer" :style="{ height: height + 'px' }">
                <canvas :id="'canvas-'+question.id"></canvas>
            </div>

            <div class="answer">
                <h2>打分(满分{{question.score}})</h2>
                <div class="input-score">
                    <van-field v-model="marking_score" placeholder="请输入分数" />
                </div>
            </div>

            <div class="answer">
                <h2>评语</h2>
                <div class="input">
                    <van-field v-model="marking_comment" rows="1" size="large" autosize type="textarea" placeholder="请输入评语" />
                </div>
            </div>

            <div class="edit-show">
                <svg-icon style="width: 20px; height: 20px;" icon-class="Pen" @click="showBottom = true" />
            </div>
            <van-button type="primary" block style="margin-top: 20px; border-radius: 20px" @click="saveCanvas">{{ btnText }}</van-button>
            <van-popup v-model:show="showBottom" position="bottom" :style="{ height: '50%' }">

                <div id="controls" class="controls">
                    <van-field v-model="color" type="color" label="批改颜色" />
                    <van-field name="slider" label="画笔粗细">
                        <template #input>
                            <van-slider v-model="size" min="1" max="50" />
                        </template>
                    </van-field>
                    <van-field name="slider" label="画布大小">
                        <template #input>
                            <van-slider v-model="height" min="100" max="1000" step="50" />
                        </template>
                    </van-field>
                    <van-field name="slider" label="选择画笔">
                        <template #input>
                            <svg-icon style="width: 20px; height: 20px" icon-class="Pen" @click="setPenMode" />
                        </template>
                    </van-field>
                    <van-field name="slider" label="选择橡皮">
                        <template #input>
                            <svg-icon style="width: 20px; height: 20px" icon-class="xiangpi" @click="setEraserMode" />
                        </template>
                    </van-field>
                </div>
            </van-popup>
        </div>
    </div>
</template>

<script>
import { fabric } from "fabric";
import { Dialog, Toast } from 'vant';
import { uplodFile } from "@/api/professional";
import { generateRandomString } from "@/utils/tool.js"
import { uploadFile, updateCorrectionResult, getCorrectionResultInfo } from "@/api/professional/index.js";
import katex from 'katex'
export default {
    data() {
        return {
            btnText: '保存',
            marking_comment: "",
            marking_score: '',
            timerId: null,
            questionList: [],
            showBottom: false,
            score: "",
            color: '#ff0000',
            size: 2,
            height: 500, // Default canvas container height
            canvas: null,
            erasing: false,
            stuAnswer: {},
            bgimgs: [],
        };
    },
    props: {
        question: {
            default: {}
        }
    },
    mounted() {
        this.initCanvas();
    },
    methods: {
        initCanvas() {
            this.canvas = new fabric.Canvas('canvas-'+this.question.id);
            this.canvas.setHeight(this.height);
            this.canvas.setWidth(document.getElementById('canvas-container').offsetWidth);

            this.loadBackgrounds(this.bgimgs);
            this.canvas.isDrawingMode = true;
            this.canvas.freeDrawingBrush.width = this.size;
            this.canvas.freeDrawingBrush.color = this.color;
        },

        loadBackgrounds(imageUrls) {
            const canvasWidth = this.canvas.getWidth();
            let topOffset = 0;
            let totalHeight = 0;

            const loadImage = (imageUrl) => {
                return new Promise((resolve) => {
                    fabric.Image.fromURL(imageUrl, (img) => {
                        const scaleFactor = canvasWidth / img.width;
                        const scaledHeight = img.height * scaleFactor;
                        img.set({
                            left: 0,
                            top: topOffset,
                            scaleX: scaleFactor,
                            scaleY: scaleFactor,
                            selectable: false,
                            evented: false,
                            crossOrigin: 'anonymous'
                        });

                        topOffset += scaledHeight;
                        totalHeight += scaledHeight;
                        this.canvas.add(img);
                        this.canvas.sendToBack(img);
                        resolve();
                    }, { crossOrigin: 'anonymous' });
                });
            };

            Promise.all(imageUrls.map(loadImage)).then(() => {
                this.canvasHeight = totalHeight;
                this.height = totalHeight;
                this.canvas.setHeight(this.canvasHeight);
                this.canvas.renderAll();
            });
        },

        setPenMode() {
            this.erasing = false;
            this.canvas.isDrawingMode = true;
            this.canvas.defaultCursor = 'default';
            this.canvas.off('mouse:move', this.erase);
        },

        setEraserMode() {
            this.erasing = !this.erasing;
            this.canvas.isDrawingMode = !this.erasing;
            if (this.erasing) {
                this.canvas.defaultCursor = `default`;
                this.canvas.on('mouse:move', this.erase);
            } else {
                this.canvas.defaultCursor = `default`;
                this.canvas.off('mouse:move', this.erase);
            }
        },

        erase(o) {
            if (!this.erasing) return;
            const pointer = this.canvas.getPointer(o.e);
            const eraserSize = this.size;

            const objects = this.canvas.getObjects();
            for (let i = objects.length - 1; i >= 0; i--) {
                const obj = objects[i];
                if (obj === this.canvas.backgroundImage || obj.evented === false) continue;
                if (obj.left <= pointer.x && pointer.x <= obj.left + obj.width * obj.scaleX &&
                    obj.top <= pointer.y && pointer.y <= obj.top + obj.height * obj.scaleY) {
                    this.canvas.remove(obj);
                }
            }
        },

        saveCanvas() {
            const dataURL = this.canvas.toDataURL({
                format: 'png',
                quality: 1
            });
            this.uploadImage(dataURL);
        },

        dataURLtoBlob(dataURL) {
            const byteString = atob(dataURL.split(',')[1]);
            const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
            const ab = new ArrayBuffer(byteString.length);
            const ia = new Uint8Array(ab);
            for (let i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }
            return new Blob([ab], { type: mimeString });
        },

        async uploadImage(dataURL) {
            // 更新前判断当前 作业是否已经被批改()
            const params = {
                id: this.question.id,
                unionid: this.$route.params.unionid
            }
            const result = await getCorrectionResultInfo(params)
            if (result.code == 2) {
                Toast.fail(result.msg)
                result
            }
            if (result.code == 0) {
                try {
                    await Dialog.confirm({ title: '提示', message: result.msg })
                } catch (e) {
                    return
                }
            }
            const blob = this.dataURLtoBlob(dataURL);
            const formData = new FormData();
            const fileName = generateRandomString(6) + '.png'
            formData.append('file', blob, fileName);
            try {
                Toast.loading({
                    message: '请求中',
                    forbidClick: true,
                    duration: 0
                })
                const ret = await uploadFile(formData)
                const data = {
                    id: this.question.id,
                    marking_pic: ret.fullurl,
                    marking_comment: this.marking_comment,
                    marking_score: this.marking_score,
                    unionid: this.$route.params.unionid
                }
                const id = this.question.id
                await updateCorrectionResult(data);
                Toast.success("批改成功")
                this.btnText = "保存(当前已批改)"

            } catch (e) {
                Toast.fail(e.message)
            } finally {
                Toast.clear()
            }
        }
    },
    computed: {
        title() {
            if (this.question.pname == "数学") {
                const title = this.question.title.replace(/<p>/g, '').replace(/<\/p>/g, '').replace(/<br\s*\/?>/g, '').replace(/amp;/g, '');
                return katex.renderToString(title, {
                    throwOnError: false
                });
            } else {
                return this.question.title
            }
        },
        answer(){
            return JSON.parse(this.question.answer)
        }
    },
    watch: {
        color(newVal) {
            if (!this.erasing) {
                this.canvas.freeDrawingBrush.color = newVal;
            }
        },
        size(newVal) {
            this.canvas.freeDrawingBrush.width = newVal;
        },
        height(newVal) {
            this.$refs.canvasContainer.style.height = newVal + 'px';
            this.canvas.setHeight(newVal);
            this.canvas.renderAll();
        },
        question: {
            immediate: true,
            deep: true,
            handler(val) {
                console.log(this.question)
                if (typeof val !== "{}") {
                    this.stuAnswer = JSON.parse(val.answer);

                    //console.log('stuAnswer',this.stuAnswer.url)
                    //console.log(typeof this.stuAnswer.url)
                    const url = this.stuAnswer.url[0].url??this.stuAnswer.url;
                    this.bgimgs = url.split(',');
                }
            }
        }
    }
};
</script>

<style scoped lang="less">
.container {
    padding: 0 9px;
    padding-bottom: 20px;
}

.edit-show {
    position: absolute;
    right: 10px;
    top: 10px;
    height: 44px;
    width: 44px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#canvas-container {
    position: relative;
    display: inline-block;
    width: 100%;
}

#canvas {
    width: 100%;
    height: 100%;
}

#controls {
    margin-top: 10px;
}

.controls {}

.type {
    width: 43px;
    height: 22.5px;
    background: #CDF3E7;
    border-radius: 6px;
    margin-top: 8px;
    font-weight: 400;
    font-size: 10px;
    color: #01997A;
    line-height: 22.5px;
    text-align: center;
}

.question-title {
    margin-top: 7.5px;

    .icon {
        width: 70px !important;
    }
}

.answer {
    margin-top: 7.5px;

    h2 {
        font-size: 14px;
        font-weight: 400;
        color: #777777;
    }

    .input {
        margin-top: 6px;
        min-height: 116.5px;
        background: #FFFFFF;
        border-radius: 11px;
        border: 1px solid #707070;
        padding: 10px;
    }

    .input-score {
        margin-top: 6px;
        background: #FFFFFF;
        border-radius: 11px;
        border: 1px solid #707070;
        padding: 10px;
    }

    ::v-deep .van-field__control {
        height: 15px;
        line-height: 15px;
        font-size: 16px;
    }
}
</style>
