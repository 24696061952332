<template>
    <div class="container">
        <!-- 头部 -->
        <div class="head">
            <div class="grid-container">
                <div class="data-report-grid">
                    <canvas style="width: 100%; text-align: center; height: 140px;"id="dashboard"></canvas>
                    <div class="extra-info van-hairline--top">
                        <div class="info">
                            <span>{{ reportData.type == 0 ? "题库名称:" : "试卷名称:" }}</span>
                            <span class="point-value">{{ reportData.subject }}</span>
                        </div>
                        <div class="info">
                            <span>交卷时间:</span>
                            <span class="point-value">{{ reportData.submit_time }}</span>
                        </div>
                        <div class="info">
                            <span>考试时长:</span>
                            <span class="point-value">{{ totalTime }}</span>
                        </div>
                    </div>
                </div>
                <div class="info-board">
                    <div class="item">
                        <div class="top-text">
                            <span>{{ hasContainSubjective ? 0 : reportData.rightRate }}</span>
                            <span>%</span>
                        </div>
                        <span>正确率</span>
                    </div>
                    <div class="item van-hairline--right">
                        <div class="top-text">
                            <span>{{ hasContainSubjective ? 0 : reportData.avgScore }}</span>
                            <span></span>
                        </div>
                        <span>平均分</span>
                    </div>
                    <div class="item van-hairline--right">
                        <div class="top-text">
                            <span>{{ hasContainSubjective ? 0 : reportData.maxScore }}</span>
                            <span></span>
                        </div>
                        <span>最高分</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="content">

            <div class="q-answer">
                <div class="all-course-h1">
                    <div class="green-block-big">

                    </div>
                    <span>答题卡</span>
                </div>
                <div class="list">
                    <template v-for="(item, index) in reportData.answer_card">
                        <div class="item-container">
                            <div class="item" :class='{ "item-sel": item.isRight != 1 }'>
                                {{ item.index - 0 + 1 }}
                            </div>
                        </div>
                    </template>

                </div>
                <div class="bottom-info">
                    <div class="bottom-info-item">
                        <div class="round first">

                        </div>
                        <span>正确</span>
                    </div>

                    <!-- 						<div class="bottom-info-item">
												<div class="round second">
													
												</div>
												<span>半对</span>
											</div> -->

                    <div class="bottom-info-item ">
                        <div class="round third">

                        </div>
                        <span>错误</span>
                    </div>
                </div>
            </div>


            <div class="bottom-btn" @click="toAnswer">
              {{ submitText }}
            </div>

        </div>


    </div>
</template>

<script>
import { selectTemplate, selectTemplateAnswer, formatLocalTime,formatGoneTime} from "@/utils/tool.js"
import { getReportData,sendTaskSubmitTemplateMessage  } from "@/api/professional/index.js"
import { mapState } from "vuex"
import { Dialog } from "vant"
export default {
    data() {
        return {
            canvas: "",
            ctx: '',
            hasContainSubjective: false,
            reportData: {},
            hideBtn:0
        }
    },
    components: {},
    computed: {
        //获取答题总时间
        totalTime() {
            if (typeof this.reportData.used_time === "number") {
                return formatGoneTime(this.reportData.used_time)
            } else {
                return "0"
            }
        },
        submitText(){
				if(this.reportData.type == 2){
					if(this.reportData.isCorrection == 1){
						return "返回个人中心"
					}else{
						return "通知老师批改"
					}
				}
				return '错题解析'
			},
        ...mapState('professionalConfig', ['currentTypes', 'currentIndex']),
    },
    methods: {
        toAnswer() {
            	//模考试卷 跳转到刷题界面
				if(this.reportData.type == 2){
                    if(this.reportData.isCorrection==1){
						uni.navigateBack()
                    }else{
                        Dialog ({
                            title: '提示',
                            message: '确认通知老师批改',}).then(async (res) => {
                                if (res == "confirm") {
                                    await sendTaskSubmitTemplateMessage({report_id:this. reportData.id})
                                    uni.reLaunch({
                                        url:"/subpkg/allquestion/allquestion?type=2"
                                    })
                                }
                            })
                    }
				}else{
					//进入当前的答案模板 从第一道题开始
					this.$store.commit("professionalConfig/setCurrentIndex", 0)
					selectTemplateAnswer(this.currentTypes[this.currentIndex], false)
				}
        },
        drawMeter(value, allScore, score) {
            var canvas = document.getElementById('dashboard');
            var ctx = canvas.getContext('2d');
            // 仪表盘参数

            const centerY = 90;
            const radius = 70;
            let wInfo = window.innerWidth-20;
            //  const query = uni.createSelectorQuery().in(this);
            // let rect =  await  query.select('.data-report-grid').boundingClientRect().exec();
            // console.log(rect)
            //计算起始点     
            const centerX = parseInt((wInfo - radius) / 2)
            // 绘制仪表盘背景
            ctx.beginPath();
            ctx.arc(centerX, centerY, radius, Math.PI, 2 * Math.PI, false);
            ctx.lineWidth = 8;
            ctx.strokeStyle = '#CDF3E7';
            ctx.stroke();

            // 动态绘制仪表盘
            let endAngle = Math.PI + value * Math.PI / 100;

            ctx.beginPath();
            ctx.arc(centerX, centerY, radius, Math.PI, endAngle, false);
            ctx.lineWidth = 8;
            ctx.strokeStyle = '#009c7b';
            ctx.stroke();

            // 计算尾端圆的坐标
            if (value < 4) {
                endAngle = Math.PI + 4 * Math.PI / 100;
            }
            if (value >= 96) {
                endAngle = Math.PI + 96 * Math.PI / 100;
            }
            const endX = centerX + radius * Math.cos(endAngle);
            const endY = centerY + radius * Math.sin(endAngle);

            // 绘制尾端的实心圆
            ctx.beginPath();
            ctx.arc(endX, endY, 10, 0, 2 * Math.PI, false);
            ctx.fillStyle = '#009c7b';
            ctx.fill();

            // 绘制尾端的空心圆环
            ctx.beginPath();
            ctx.arc(endX, endY, 14, 0, 2 * Math.PI, false);
            ctx.lineWidth = 5;
            ctx.strokeStyle = '#fff';
            ctx.stroke();

            // 绘制文本
            ctx.font = '20px Arial';
            ctx.fillStyle = '#201E2E';
            ctx.textAlign = 'center';
            ctx.fillText(score, centerX - 8, centerY - 20);


            ctx.font = '12px Arial';
            ctx.fillStyle = '#201E2E';
            ctx.textAlign = 'center';
            ctx.fillText('分', centerX + 8, centerY - 20);


            ctx.font = '12px Arial';

            ctx.fillStyle = '#777777';
            ctx.textAlign = 'center';
            ctx.fillText(`总分${allScore}分`, centerX, centerY);
            
            // 将以上绘图操作立即渲染到 canvas
           // ctx.draw();
        }
    },
    mounted() {
        //this.drawMeter(48)
    },
    created() {
        const { report_id } = this.$route.params
        this.hideBtn =  this.$route.params.hideBtn??0
        //获取报告数据
        getReportData({ report_id }).then(res => {
            if (res.has_contain_subjective == 1 && res.type !=2) {
                this.hasContainSubjective = true
                this.reportData = res
                this.drawMeter(0, 0, 0);
            } else {
                this.reportData = res
                //绘制仪表盘
                const cicleAngle = parseInt(this.reportData.score * 100 / this.reportData.total_score)
                this.drawMeter(cicleAngle, this.reportData.total_score, this.reportData.score);
            }
        })
    },
}
</script>

<style lang="less" scoped>
.container {
    position: relative;
    min-height: 100vh;
    background-color: #F6F7FB;
    padding-bottom: 30px;

    .head {
        background-image: url("https://yanqu-online-mini-1300870289.cos.ap-nanjing.myqcloud.com/%E7%BB%84%20224%402x.png");
        background-size: cover;
        height: 240px;
        padding-top: 80px;
        position: relative;

        .title {
            padding: 0 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .avatar {
                    width: 50px;
                    height: 50px;
                    background: #FFFFFF;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    image {
                        width: 24px;
                        height: 33px;

                    }


                }

                .info {
                    margin-left:2px;

                    .top-text {
                        color: #201E2E;
                        font-size: 16px;
                        font-weight: bold;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;

                        image {
                            width: 20px;
                            height: 20px;
                        }

                        .coin {
                            color: #323232;
                            font-size: 11px;
                        }
                    }

                    .bottom-text {
                        margin-top: 6px;
                        color: #6D6D6D;
                        font-size: 11px;
                    }
                }
            }

            .right {
                width: 116px;
                height: 54px;
                background: #E0F9F1;
                opacity: 0.42;
                border-radius: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                span {
                    color: #4D504F;

                    &:last-child {
                        font-size: 15px;
                        padding: 2px 0;
                    }
                }
            }
        }

        .data-report-grid {
            width: 100%;
            padding: 0 18px;
            display: flex;
            align-items: center;
            background-color: #fff;
            justify-content: center;

            .extra-info {
                width: 100%;

                .info {}
            }
        }
    }

    .content {
        padding: 0 18px;
        background-color: transparent;
        margin-top: 100px;

    }

    .grid-container {
        padding: 0 18px;
        position: relative;
        top: -50px;
    }

    .data-report-grid {
        width: 100%;
        background-color: #fff;
        border-radius: 14px;
        display: flex;
        flex-direction: column;
        align-content: center;
        box-sizing: border-box;
        justify-content: center;

        .extra-info {
            font-size: 13px;
            color: #5A5A5A;
            padding-bottom: 12px;

            .info {
                margin-top: 8px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 14px;
            }
        }
    }

    .info-board {
        background: #F6F7FB;
        border-radius: 9px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 65px;
        background-color: #fff;
        margin-top: 17px;

        .item {
            text-align: center;
            flex: 1;
            color: #777;
            font-size: 9px;

            .top-text {
                span {
                    font-size: 16px;
                    color: #201E2E;

                    &:last-child {
                        color: #777;
                        font-size: 9px;
                    }
                }
            }
        }
    }

    .q-answer {
        margin-top: 18px;
        padding: 0 18px;
        background-color: #fff;
        border-radius: 14px;

        .all-course-h1 {
            background-color: #FFF;
            padding: 16px 0;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .green-block-big {

                width: 9px;
                height: 19px;
                background: #01997A;
                border-radius: 0px 5px 0px 5px;

            }

            span {
                padding-left: 5px;
                height: 22px;
                font-weight: bold;
                font-size: 16px;
                color: #1F1F27;
            }
        }

        .list {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-content: space-between;
            padding-bottom: 35px;

            .material {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                align-content: space-between;
                border-bottom-style: dashed;
            }

            .item-container {
                width: 20%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 10px 0;

                .item {
                    background-color: #009c7b;
                    color: #fff;
                    height: 37px;
                    width: 37px;
                    border-radius: 50%;
                    line-height: 37px;
                    text-align: center;
                    font-size: 14px;
                }

                .item-sel {
                    background-color: #EE7878;
                }

            }

        }

        .bottom-info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 0 40px;
            padding-bottom: 40px;

            .bottom-info-item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 9px;
                color: #777777;
                text-align: center;

                div {
                    height: 10px;
                    width: 10px;
                    border-radius: 50%;

                    // &:n {
                    // 	background-color: #01997A;
                    // }

                    // &:last-child{
                    // 	background-color: #EE7878;
                    // }
                }

                .first {
                    background-color: #01997A;
                }

                .second {
                    background: linear-gradient(to right, #EE7878 50%, #01997A 50%);
                }

                .third {
                    background-color: #EE7878;
                }

                span {
                    height: 10px;
                    text-align: center;
                    line-height: 10px;
                    margin-left: 24px;
                }
            }
        }
    }

    .bottom-btn {
        margin: 35px auto;
        width: 200px;
        height: 40px;
        background: linear-gradient(268deg, #01997A 0%, #08AB8A 100%);
        border-radius: 20px;
        text-align: center;
        line-height: 40px;
        color: #fff;
    }
}
</style>