import user from "./user/index.js"
import professionalConfig from "./professional/index.js"
import cover from "./cover/index.js"
import Vue from 'vue';
import Vuex from 'vuex';
import origin from './origin/index.js'
Vue.use(Vuex);
const store = new Vuex.Store({
    state: {
    },
    getters: {
        token: state => state.user.userInfo.token,
    },
    mutations: {
    },
    actions: {

    },
    modules: {
        origin,
        user,
        professionalConfig,
        cover
    }

})
export { store }

