<template>
    <div class="container">
        <edit-pic v-for="item in  questionList" :key="item.id" :question="item"></edit-pic>
        <van-button :loading="sendLoading" type="warning" block style="margin-top: 20px;" @click="sendMsgToStu">通知学生</van-button>
    </div>
</template>
<script>
import { Dialog ,Toast} from 'vant';
import { getWaitForCorrection, resetCorrectionlock, sendCorrectionDoneRemind } from "@/api/professional"
import editPic from "@/components/edit_pic"
export default {
    data() {
        return {
            sendLoading: false,
            timerId: null,
            questionList: [],
            showBottom: false,
            score: "",
            color: '#ff0000',
            size: 2,
            height: 500, // Default canvas container height
            canvas: null,
            erasing: false,
            clearPng: "https://yanqu-online-mini-1300870289.cos.ap-nanjing.myqcloud.com/xiangpi.png",
            taskImg: "https://yanqu-online-mini-1300870289.cos.ap-nanjing.myqcloud.com/13083409461/2Rwh8XtyAjC1915709c983dcb25dc2499798788021c4.jpg"
        }
    },
    created() {
        this.getQuestionList()

    },
    mounted() {
        /*Dialog({
            message: '当前孙老师正在批改',
        })*/
        //this.initCanvas();
        // 获取报告信息

    },
    methods: {
        async getQuestionList() {
            const params = {
                report_id: this.$route.params.report_id,
                unionid: this.$route.params.unionid
            }

            //获取待批改的试卷信息
            const ret = await getWaitForCorrection(params);
            if (ret.code == 0) {
                await Dialog({
                    message: ret.msg
                })
                this.$router.push({
                    path: "/empty"
                })
            }
            this.questionList = ret.data
            if (this.timerId !== null) {
                clearInterval(this.timerId)
            }
            this.timerId = setInterval(async () => {
                const resetRet = await resetCorrectionlock(params)
                if (resetRet.code == 0) {
                    //重置失败清空定时器
                    clearInterval(this.timerId)
                    await Dialog({
                        message: "超时退出"
                    })
                    this.$router.push({
                        path: "/empty"
                    })
                }
            }, 60000)
        },

        sendMsgToStu() {
            Dialog({
                showCancelButton: false,
                title: '提示',
                message: '通知学生作业批改完成'
            }).then(async () => {
                this.sendLoading = true
               const res = await sendCorrectionDoneRemind({report_id:this.$route.params.report_id})
               this.sendLoading = false
               if(res !=false){
                Toast(res.msg)
               }
            })
        }

    },
    components: {
        editPic
    }
}
</script>
<style scoped lang="less">
.edit-show {
    position: fixed;
    left: 10px;
    top: 20%;
    height: 44px;
    width: 44px;
    border-radius: 50%;
    border: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
}

#canvas-container {
    position: relative;
    display: inline-block;
    width: 100%;
}

#canvas {
    width: 100%;
    height: 100%;
}

#controls {
    margin-top: 10px;
}

.controls {}
</style>