import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/style.less'
import { store } from "@/store/index.js"
import 'vant/lib/index.css';
import vant, { Toast, Dialog } from "vant"
import { getQuestionListByPqid, getConfig, getProgress } from "@/api/professional/index.js"
import { whiteRouterList } from "@/utils/setting.js"
import "katex/dist/katex.min.css"
import "@/icons"
Vue.use(vant)
Vue.prototype.$globalObj = {
  callback: null,
  index: -1
}
let loading = null
async function init() {
  loading = Toast.loading({
    message: '加载中...',
    duration: 0,
    forbidClick: true,

  })
  //获取浏览器参数
  let url = window.location.href;
  let urlStr = url.split('?')[1]
  const urlSearchParams = new URLSearchParams(urlStr)
  const user = Object.fromEntries(urlSearchParams.entries())
  if (user.hasOwnProperty("phone") && user.hasOwnProperty('token')) {
    //更新用户信息到状态管理
    store.commit("user/setUser", { phone: user.phone, token: user.token })
  }
  if (user.hasOwnProperty('origin_name')) {
    //更新用户信息到状态管理
    store.commit("origin/setOriginName", user.origin_name)
  }

  if (user.hasOwnProperty('startPage')) {
    //更新用户信息到状态管理
    store.commit("professionalConfig/setCurrentStartPage", user.startPage)
  }
  let userConfig = await getConfig();
  if (userConfig !== false) {
    store.commit("professionalConfig/setConfig", userConfig);

    if (user.hasOwnProperty('pqid')) {
      //获取当前题库的信息
      //构造答题数据
      let params = {
        pqid: user.pqid,
        perPageNum: userConfig.num
      }

      //答案模板
      const routerName = window.location.hash.split('/')
      if (routerName[1].includes("answer")) {
        params['mockExam'] = 1
      }
      let questionList = await getQuestionListByPqid(params)
      //题目列表
      let answeringQuestion = []
      //获取题目列表
      questionList.forEach(obj => {
        // 构造当前 题目的列表数据
        answeringQuestion.push({
          ...obj,
          cateName: user.cateName,
          type: user.type,
          pqid: user.pqid,
          total_time: user.total_time,
          id: obj.qid,
          subtitle: obj.qtitle,
          professionTitle: user.title,
          qtype: obj.qtype,
          aboutId: user?.aboutId ?? 0
        })
      })
      store.commit("professionalConfig/setCurrentTypes", answeringQuestion)

      //默认第一道题开始
      let startIndex = 0
      if (userConfig.do_unread == 1) {
        //获取题库该中 已经做了多少道题
        let res = await getProgress({ pqid: user.pqid })
        if (res !== false) {
          startIndex = res.currentIndex
        }
      }
      store.commit("professionalConfig/setCurrentIndex", startIndex);
    }

  }

  const app = new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app')

  document.title = user.title || "数学题库"
}

const hashArr = window.location.hash.split('/')
//白名单内的url 不用初始化登录信息
if (typeof hashArr[1] == "string" && whiteRouterList.includes(hashArr[1])) {
  const app = new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app')

  document.title = "作业批改"

} else {

  init()
    .then(() => {
      //router.push("/mathblank")
    })
    .catch((e) => {
      if (loading !== null) {
        loading.close()
        loading = null
      }
      Dialog({
        message: e.message,
      })
      Toast.fail({
        message: '网络错误'
      })
    }).finally(() => {
      if (loading === null) return
      loading.close()
    })

}