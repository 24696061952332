import axios from 'axios'
import { BASE_URL } from '@/config/config.js'
import { store } from "@/store/index.js"

const http = axios.create({
  baseURL: BASE_URL,
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
})

// 添加请求拦截器
http.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  if (typeof store.getters.token != 'undefined') {
    config.headers['token'] = store.getters.token
  }
  if (typeof store.getters.originName != 'undefined') {
    config.headers['Origin-Name'] = store.getters.originName
  }
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 添加响应拦截器
http.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  return response.data;
}, function (error) {
  // 对响应错误做点什么
  return Promise.reject(error);
});

export { http }
