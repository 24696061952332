import {
    answerTemplate
} from "./setting.js"
import router from '@/router/index.js'
/**
 * 格式化时间
 * @param {int} seconds
 */
export function formatGoneTime(seconds) {
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    // 使用padStart方法保证时间部分总是两位数字
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');

    return `${formattedMinutes}:${formattedSeconds}`;
}
/**
 * 选择进入的模板
 * @param {Object} item
 */
export function selectTemplate(item) {
    switch (item.qtype) {

        //进入答题页面
        case "SINGLE": {
            console.log('aaaa')
            router.push("/mathsel")
            break;
        }
        case "FILL": {
            router.push("/math_fill")
            break;
        }
        case "SHORT": {
            router.push("/math_short")
            break;
        }
    }
    // console.log(item)



}
/**
 * 进入试题的答案模板
 * @param {Object} item
 */
export function selectTemplateAnswer(item, closeCurrentPage = true) {
    switch (item.qtype) {
        //进入答题页面
        case "SINGLE": {
            router.push("/math_sel_answer")
            break;
        }
        case "FILL": {
            router.push("/math_fill_answer")
            break;
        }
        case "SHORT": {
            router.push("/math_short_answer")
            break;
        }
    }
}

/**
 *  格式化时间戳
 */
export function formatLocalTime(time) {
    if (typeof time !== "number") {
        time = new Date().getTime()
    }
    const date = new Date(time);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    const dataStr = hours + ":" + minutes + ":" + seconds;
    return dataStr
}

/**
 * 随机字符串
 */
export function generateRandomString(length) {
    // 定义可用字符的集合
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';

    // 遍历指定长度，随机选择字符并追加到结果字符串
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
    }

    return result;
}
