<template>
	<div class="container">
		<div class="list">
			<div class="item-container" v-for="(item, index) in currentTypes">
				<div @click="toTemplate(item, index)" class="item" :class='{ "item-sel": hasAnswerIndexList.includes(index)|| correctionCompleted || isFromRecordsList }'>
					{{ index + 1 }}
				</div>
			</div>
		</div>
		<div class="btn-container">
			<div class="btn" @click="submit">
				{{(correctionCompleted == 1 || isFromRecordsList)?'查看答题报告':'提交'}}
			</div>
		</div>
	</div>
</template>

<script>
import mixins from '@/mixins';
import { mapState } from 'vuex'
import { selectTemplate, selectTemplateAnswer } from "@/utils/tool"
import { subjectTypeArr } from "@/utils/setting.js"
import { Dialog, Toast } from "vant"
export default {
	data() {
		return {
			forbidToQuestion: 0
		};
	},
	created() {
		if (typeof this.$route.query.forbidToQuestion != 'undefined') {
			this.forbidToQuestion = this.$route.query.forbidToQuestion
		}
	},
	mixins: [mixins],
	computed: {
		hasAnswerIndexList() {
			return this.currentAnswerList.map(item => item.index)
		},
		...mapState('professionalConfig', ['currentTypeSet'])
	},
	methods: {
		//跳转进度，当前科目信息
		toTemplate(item, index) {
			Toast.fail("本次考试时间已用完，请提交答题卡！")
			//设置当前题目序号
			this.setCurrentIndex(index)
			// 跳转答题卡
			if (this.currentAnswerStatus.isSubmit == true) {
				selectTemplateAnswer(item)
			} else {
				selectTemplate(item)
			}
		},
		async submit() {

			//已经批改完成
			if (this.correctionCompleted == 1) {
				// return uni.redirectTo({
				// 	url: `/subpkg/examination_report/examination_report?report_id=${this.currentType.answer_records.report_id}&hideBtn=1`
				// })
				return this.$router.push({ name: "examinationReport", params: { report_id: this.currentType.answer_records.report_id, hideBtn:1} })
			}

			if (this.isFromRecordsList) {
				return this.$router.push({ name: "examinationReport", params: { report_id: this.currentType.report_id, hideBtn:1} })
				// return uni.redirectTo({
				// 	url: `/subpkg/examination_report/examination_report?report_id=${this.currentType.report_id}&hideBtn=1`
				// })
			}

			//模拟试卷 直接进入答案
			if (this.currentType.type == 2) {
				//重置答题索引
				this.$store.commit("professionalConfig/setCurrentIndex", 0)
				Dialog({
					title: "提示",
					message: "查看模拟题答案"
				}).then((res) => {
					if (res == "confirm") {
						selectTemplateAnswer(this.currentTypes[this.currentIndex], false)
					}
				})

			} else {
				//判断当前全部是客观题
				const result = this.currentTypes.every(item => subjectTypeArr.includes(item.qtype))
				console.log(result)
				//含有主观题不生成答题报告直接到 答案
				if (result == false) {
					this.$store.commit("professionalConfig/setCurrentIndex", 0)
					const ret = await this.addRecordsData()

					selectTemplateAnswer(this.currentTypes[this.currentIndex], false)
					return;
				}
				const ret = await this.addRecordsData()
				if (ret != false) {
					this.$router.push({ name: "examinationReport", params: { report_id: ret.report_id, hideBtn:0 } })
				}
			}
		}
	}
}
</script>

<style lang="less" scoped>
.container {
	min-height: 100vh;

	.center {
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: #2D2D2D;
		font-size: 16px;
	}

	.list {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-content: space-between;
		padding: 0 18px;
		padding-bottom: 60px;

		.item-container {
			width: 20%;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 10px 0;

			.item {
				background-color: #01997A;
				color: #fff;
				height: 37px;
				width: 37px;
				border-radius: 50%;
				line-height: 37px;
				text-align: center;
				font-size: 14px;
			}

			.item-sel {
				background-color: #EE7878;
			}
		}
	}
}

.btn-container {
	box-sizing: border-box;
	position: fixed;
	bottom: 18px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;

	.btn {
		width: 200px;
		height: 40px;
		background: linear-gradient(268deg, #01997A 0%, #08AB8A 100%);
		border-radius: 20px;
		color: #fff;
		line-height: 40px;
		text-align: center;
	}
}
</style>
