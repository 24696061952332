let professionalConfig = {
	namespaced: true,
	state: {
		config: {},
		currentTypes: {},
		currentIndex: 0,
		//当前答案列表
		currentAnswerList: [],
		//当前的答题状态
		currentAnswerStatus: {
			isSubmit: false,
			submitTime: ""
		},

	},
	mutations: {
		//设置题目配置
		setConfig(state, payload) {
			state.config = payload
		},
		//设置当前的题目集合
		setCurrentTypes(state, payload) {
			state.currentTypes = typeof payload !== "undefined" ? payload : []

		},
		//设置正在做的题目
		setCurrentIndex(state, payload) {
			state.currentIndex = payload
			//

		},
		//更改状态管理里面某道题的数据
		changeCurrentTypesByCurrentIndex(state, payload) {
			if (Object.prototype.toString.call(payload) !== '[object Object]') {
				return
			}
			Object.keys(payload).forEach(item => {
				state.currentTypes[state.currentIndex].hasOwnProperty(item) && (state.currentTypes[state.currentIndex][item] = payload[item])

			})
		},
		//清空答案
		clearAnswerList(state) {
			state.currentAnswerList = []
		},
		//设置答案
		setAnswerList(state, payload) {
			if (Object.prototype.toString.call(payload) === '[object Object]') {
				const hasExisted = state.currentAnswerList.find(item => item.index == payload.index)
				//当前该题答案已经存在
				if (typeof hasExisted !== "undefined") {
					//替换已经存在的答案
					state.currentAnswerList = state.currentAnswerList.map(item => {
						if (item.index == payload.index) {
							return payload
						}
						return item
					})
				} else {
					//不存在 直接添加
					state.currentAnswerList = [...state.currentAnswerList, payload]
				}
			}
		},
		//更改答题状态
		changeCurrentAnswerStatus(state, payload) {
			state.currentAnswerStatus = payload
		},
		//设置进入题库的页面
		setCurrentStartPage(state, payload) {
			state.currentStartPage = payload
		},
		enterCurrentStartPage(state) {

			const tabBarPages = [
				'pages/index/index',
				"pages/listen/listen",
				"pages/task/task",
				"pages/encourage/encourage",
				"pages/center/center",
			]

			if (tabBarPages.includes(state.currentStartPage)) {
				uni.switchTab({
					url: '/' + state.currentStartPage
				})
			} else {
				uni.redirectTo({
					url: '/' + state.currentStartPage
				})
			}
			state.currentStartPage = ""
		}
	}

}
export default professionalConfig