import { mapState, mapMutations } from "vuex"
import { getQuestionDetail, addAnswerRecords, addAnswerAndReportData, taskOtherRecord } from "@/api/professional/index.js"
import { selectTemplate, selectTemplateAnswer, formatGoneTime } from "@/utils/tool.js"
import { subjectTypeArr } from "@/utils/setting.js"
import { Toast as showToast, Dialog as showDialog } from "vant"
import katex from 'katex'
import Vue from "vue"
export default {
    data() {
        return {
            timeval: 0,
            question: {},
            selfScore: 0,
            answer: "",
            fileList: [],
            timerId: null,
            //完形填空 阅读理解 新题型 单独处理
            currentSelArr: [],
            currentChoose: 0,
            timerId: null
        };
    },
    mounted() {
        this.startTimer()
    },

    methods: {
        startTimer() {
            if (this.timerId != null) {
                clearInterval(this.timerId)
                this.timerId = null
            }
            this.timerId = setInterval(() => {

                //模拟考试超时直接提交生成答题卡
                if (this.currentType.type == 2) {
                    //用时
                    const totalTime = this.currentAnswerList.reduce((total, item) => {
                        return total + item.answer.timeval
                    }, 0)
                    if (totalTime >= this.currentType.total_time * 60) {
                        showDialog({
                            showCancelButton: false,
                            title: '提示',
                            message: '本次考试时间已用完，进入答题卡提交'
                        }).then(() => {
                            this.$router.push({
                                url: '/answer_sheet?forbidToQuestion=1'
                            })
                        })
                    }
                }

                this.timeval += 1
            }, 1000)
        },
        ...mapMutations("professionalConfig", ['setCurrentIndex', 'changeCurrentAnswerStatus']),

        //模考类型统一按答题模式处理
        toPrevAnswerAtMockType() {
            if (this.currentIndex == 0) {
                return showToast("已经是第一题了")
            }
            //题型相同
            if (this.currentType.qtype == this.currentTypes[this.currentIndex - 1].qtype) {
                //更新答题索引
                this.setCurrentIndex(this.currentIndex - 1)
            } else {
                selectTemplateAnswer(this.currentTypes[this.currentIndex - 1])
            }
        },
        answerPrev() {
            //模考类型单独处理 不区分答题和背题模式
            if (this.currentType.type == 2) {
                return this.toPrevAnswerAtMockType()
            }
            //练习模式
            if (this.config.type == 0 || this.isFromRecordsList) {
                if (this.currentIndex == 0) {
                    return showToast("已经是第一题了")
                }
                if (this.currentType.qtype == this.currentTypes[this.currentIndex - 1].qtype) {
                    //更新答题索引
                    this.setCurrentIndex(this.currentIndex - 1)
                } else {
                    this.setCurrentIndex(this.currentIndex - 1)
                    selectTemplateAnswer(this.currentTypes[this.currentIndex])
                }
            } else {
                selectTemplate(this.currentTypes[this.currentIndex])
            }
        },
        /**
         * 保存当前答题结果
         */
        saveCurrentAnswer() {
            if (this.correctionCompleted == 1 || this.isFromRecordsList) {
                return
            }
            //保存答题结果
            let answer = {
                answer: this.answer,
                url: [],
                timeval: this.$refs.cheader.currentTime,
            }

            const data = {
                answer: answer,
                index: this.currentIndex,//保存当前 题库的进度
            }
            //const res = await updateAnswer(data)
            //当前结果保存本地
            this.$store.commit("professionalConfig/setAnswerList", data);
        },

        //答案解析下一题处理
        toNextAnswerTmp() {
            if (this.currentType.qtype == this.currentTypes[this.currentIndex + 1].qtype) {
                //更新答题索引
                this.setCurrentIndex(this.currentIndex + 1)
            } else {
                //更新模板
                this.setCurrentIndex(this.currentIndex + 1)
                selectTemplateAnswer(this.currentTypes[this.currentIndex])
            }
        },
        answerNext() {
            let contentTip = "已经最后一题是否返回题库列表"
            //最后一题给出提示
            if (this.currentIndex >= this.currentTypes.length - 1) {
                //提示信息
                if (this.currentType.type == 2) {
                    contentTip = "提交后批改结果在个人中心查看"
                    //模考类型 并且已经批改
                    if (this.correctionCompleted == 1) {
                        contentTip = "已经是最后一题是否跳转到个人中心"
                    }
                    showDialog({
                        title: '标题',
                        message: contentTip,
                    }).then(async () => {

                        //提交答题报告
                        if (this.currentType.type == 2) {
                            //批改结束跳转到个人中心
                            if (this.correctionCompleted == 1) {
                                uni.switchTab({
                                    url: "/pages/center/center"
                                })
                            } else {
                                showToast.loading({
                                    message: '提交中...',
                                    forbidClick: true,
                                });
                                const ret = await this.addRecordsData()
                                showToast.clear()
                                //跳转到答题报告
                                this.$router.push({ name: "examinationReport", params: { report_id: ret.report_id } })
                            }

                        } else {
                            this.$store.commit('professionalConfig/enterCurrentStartPage')
                        }

                    });
                    return
                }
            }
            //当前类型是模拟试卷 把 自测评分添加到答案里面去
            if (this.currentType.type == 2) {
                //没有批改自测阶段 添加自测数据 
                if (this.correctionCompleted != 1) {
                    //获取 用户答案
                    const data = this.currentAnswerList.find(item => item.index == this.currentIndex)
                    data.answer.selfScore = this.selfScore
                    // 保存答案
                    this.$store.commit("professionalConfig/setAnswerList", data);
                }
                return this.toNextAnswerTmp()

            }

            //判断当前的答题模式
            //如果是练习模式
            if (this.config.type == 0 || this.isFromRecordsList) {
                return this.toNextAnswerTmp()
            } else {
                selectTemplate(this.currentTypes[this.currentIndex])
            }
            // this.setCurrentIndex(this.currentIndex + 1)
            // if (this.currentIndex >= this.currentTypes.length) {
            //     //提示信息
            //     if (this.currentType.type == 2) {
            //         contentTip = "提交后批改结果在个人中心查看"
            //     }
            //     showDialog({
            //         title: '标题',
            //         message: contentTip,
            //     }).then(async () => {
            //         //提交答题报告
            //         if (this.currentType.type == 2) {
            //             showToast.loading({
            //                 message: '提交中...',
            //                 forbidClick: true,
            //             });
            //             const ret = await this.addRecordsData()
            //             showToast.clear()
            //             //跳转到答题报告
            //             this.$router.push({ name: "examinationReport", params: { report_id: ret.report_id } })
            //         } else {
            //             uni.reLaunch({
            //                 url: "/subpkg/allquestion/allquestion"
            //             })
            //         }

            //     });
            //     return
            // }
            // selectTemplate(this.currentTypes[this.currentIndex])
            // } else {
            //     if (this.currentType.qtype == this.currentTypes[this.currentIndex + 1].qtype) {
            //         //更新答题索引
            //         this.setCurrentIndex(this.currentIndex + 1)
            //     } else {
            //         this.setCurrentIndex(this.currentIndex + 1)
            //         selectTemplateAnswer(this.currentTypes[this.currentIndex])
            //     }
        },

        //上一题
        async prev() {
            if (this.currentIndex == 0) {
                return showToast("已经是第一题了")
            }
            if (this.currentType.qtype == this.currentTypes[this.currentIndex - 1].qtype) {
                //更新答题索引
                this.setCurrentIndex(this.currentIndex - 1)
            } else {
                this.setCurrentIndex(this.currentIndex - 1)
                selectTemplate(this.currentTypes[this.currentIndex])
            }

        },
        //记录答题和生成的报告数据
        async addRecordsData() {
            //写入答题记录
            let postAnswer = []
            //构建答题数据
            // this.currentAnswerList.forEach(item=>{
            // 	const postItem = {
            // 	    pqid: this.currentTypes[item.index].pqid,
            // 		qid: this.currentTypes[item.index].id,
            // 		answer:item.answer,
            // 	}
            // 	//是否客观题
            // 	if(['SINGLE', 'MULTI'].includes(this.currentTypes[item.index].qtype)){
            // 		isRight = this.currentTypes[item.index].answer == item.answer.answer
            // 	}
            // 	postAnswer.push(postItem)
            // })
            //保存答题记录到服务器
            //await addAnswerRecords(postAnswer);
            //更新答题状态
            this.changeCurrentAnswerStatus({
                isSubmit: true,
                submitTime: new Date().getTime()
            })
            let reportData = {}
            //总分
            let totalScore = 0
            //用户得分
            let currentScore = 0
            //生成答题卡数据
            let answerCard = []
            //回答正确的数目 冗余字段 方便后台统计
            let rightNum = 0
            let totalNum = 0
            //遍历答题数据
            this.currentAnswerList.forEach((item, index) => {
                //答题卡数据			
                const answerCardItem = {
                    //大题库id
                    qid: this.currentTypes[item.index].id,
                    //答题顺序
                    index: item.index
                }
                let isRight = 0 //0不正确，1正确

                //判断答案是不是数组是数组说明 英语完型填空 阅读理解和新题型
                if (Array.isArray(item.answer.answer)) {
                    const sysAnswer = JSON.parse(this.currentTypes[item.index].answer)
                    // 完形填空的数据单独存储
                    answerCardItem[this.currentTypes[item.index].qtype] = {
                        keyName: this.currentTypes[item.index].qtitle,
                        answerList: []
                    }
                    sysAnswer.forEach((sysAnswerItem) => {
                        //总分
                        totalScore += this.currentTypes[item.index].score
                        //总数量
                        totalNum++
                        //判断是否正确 获取用户答题数据
                        const userAnswer = item.answer.answer.find(userAnswerItem => userAnswerItem.index == sysAnswerItem.num - 1)
                        //判断是否正确
                        if (typeof userAnswer !== 'undefined' && userAnswer.selOption == sysAnswerItem.answer.trim().toUpperCase()) {
                            rightNum++
                            currentScore += this.currentTypes[item.index].score
                            isRight = 1
                        } else if (typeof item.answer.selfScore != 'undefined' && item.answer.selfScore > 0) {
                            //加上自测评分
                            currentScore += item.answer.selfScore - 0
                            isRight = 1
                        } else {
                            isRight = 0
                        }

                        answerCardItem[this.currentTypes[item.index].qtype].answerList.push({
                            index: sysAnswerItem.num - 1,
                            isRight: isRight
                        })
                    })

                } else {
                    //总分
                    totalScore += this.currentTypes[item.index].score
                    //判断是否正确
                    if (item.answer.answer.trim().toUpperCase() == this.currentTypes[item.index].answer.trim().toUpperCase()) {
                        rightNum++
                        //当前得分
                        currentScore += this.currentTypes[item.index].score
                        isRight = 1
                    } else {
                        isRight = 0
                    }
                    answerCardItem['isRight'] = isRight
                }
                answerCard.push(answerCardItem)
                //答题记录数据
                const postItem = {
                    pqid: this.currentTypes[item.index].pqid,
                    qid: this.currentTypes[item.index].id,
                    answer: item.answer,
                    qtype: this.currentTypes[item.index].qtype,
                }
                //主观题 一律是 不正确
                if (!subjectTypeArr.includes(this.currentTypes[item.index].qtype)) {
                    isRight = 0
                }
                postItem['isRight'] = isRight
                postAnswer.push(postItem)

            })

            //用时
            const totalTime = this.currentAnswerList.reduce((total, item) => {
                return total + item.answer.timeval
            }, 0)
            //是否包含主观题
            const hasContainSubjective = this.currentTypes.some(item => {
                if (!subjectTypeArr.includes(item.qtype)) {
                    return true
                } else {
                    return false
                }
            })

            //生成记录数据 提交后端
            reportData = {
                //专项题库id
                pqid: this.currentTypes[0].pqid,
                //主题
                title: this.currentTypes[0].professionTitle,
                //答题卡数据
                answerCard,
                //总分
                totalScore,
                //用户得分
                currentScore,
                //回答正确的数目
                rightNum,
                //题目总数量
                totalNum: totalNum > 0 ? totalNum : this.currentTypes.length,
                //答题用时
                totalTime,
                type: this.currentType.type,
                hasContainSubjective: Number(hasContainSubjective)
            }
            //任务题库
            if (this.currentType.aboutId > 0) {
                const taskData = {
                    about_id: this.currentType.aboutId,
                    type: 'professional',
                    useTime: reportData.totalTime,
                    total_ret: "100%",
                    professional_id: reportData.pqid
                }
                await taskOtherRecord(taskData)
            }
            const ret = await addAnswerAndReportData({
                postAnswer,
                reportData
            })

            return ret

        },
        //是否跳转到下一题
        async whetherToNext() {
            //练习模式先做题生成报告再显示答案
            if (this.config.type == 0 || this.currentType.type == 2) {
                if (this.currentIndex + 1 >= this.currentTypes.length) {
                    //最后一题 跳转到答题卡
                    this.$router.push({
                        path: '/answer_sheet'
                    })
                } else {
                    console.log(this.currentTypes[this.currentIndex + 1].qtype)
                    //判断下一题模板与当前模板是否一致来决定是否做页面跳转

                    if (this.currentType.qtype == this.currentTypes[this.currentIndex + 1].qtype) {
                        //更新下一题索引
                        this.setCurrentIndex(this.currentIndex + 1)
                    } else {
                        //更新下一题索引
                        this.setCurrentIndex(this.currentIndex + 1)
                        // Vue.doCallBack = () => {
                        //     this.setCurrentIndex(this.currentIndex - 1)
                        // }
                        //进入下一题
                        selectTemplate(this.currentTypes[this.currentIndex])
                    }
                }

            } else {
                //背题模式 做一题 看一题答案
                //进入当前的答案模板
                selectTemplateAnswer(this.currentTypes[this.currentIndex])
            }
        }
    },
    computed: {
        goneTime() {
            return formatGoneTime(this.timeval)
        },
        ...mapState("professionalConfig", ['currentTypes', "currentIndex", 'config', 'currentAnswerList', 'currentAnswerStatus']),
        //总题库所属id
        qid() {
            return this.currentTypes[this.currentIndex].id//大题库 试题id
        },
        //专项题库id
        pqid() {
            return this.currentTypes[this.currentIndex].pqid// 专项题库/试卷id
        },
        //类型
        ptype() {
            return this.currentTypes[this.currentIndex].type//类型是否试卷
        },
        currentType() {
            return this.currentTypes[this.currentIndex]//当前 答题的 详细题库信息,问题信息
        },
        statisticsInfo() {
            return {
                usingTime: this.timeval,
                rightRate: this.currentType.rightRateOfAllQuestion,
                easyWrongOption: this.currentType.most_error_item,
            }
        },
        //完形填空构造 问题列表
        questionList() {

            if (typeof this.currentType.material_questions == "string" && this.currentType.material_questions.length > 0) {
                let questions = JSON.parse(this.currentType.material_questions)
                return questions.map((item, index) => {
                    let returnOpt = {}
                    Object.keys(item).forEach(key => {
                        if (key.indexOf('q') != -1 && key.length == 2) {
                            returnOpt[key.slice(1).toUpperCase()] = item[key]
                        }
                    })
                    return returnOpt
                })
            } else {
                return []
            }
        },

        //是否已经批改
        correctionCompleted() {
            return this.currentType?.correction_completed
        },
        currentStartPage() {
            return this.$store.state.professionalConfig.currentStartPage
        },
        isFromRecordsList() {
            return this.currentStartPage == "collection_page/practice_record/practice_record"
        }
    },
    watch: {
        //当前 题目索引变化更改答案
        currentIndex: {
            handler: function (val) {
                if (typeof this.currentTypes[val] == "undefined") return;
                // 将公式替换为 KaTeX 渲染后的结果
                const latex = this.currentTypes[val].title.replace(/<p>/g, '').replace(/<\/p>/g, '').replace(/<br\s*\/?>/g, '').replace(/amp;/g, '');
                const title = katex.renderToString(latex, {
                    throwOnError: false
                });
                let explain = ""
                if (this.currentTypes[val].explain.includes('img')) {
                    explain = this.currentTypes[val].explain
                } else {
                    //答案解析
                    const latexExplain = this.currentTypes[val].explain
                        .replace(/<div>/g, '')
                        .replace(/<\/div>/g, '')
                        .replace(/<p>/g, '')
                        .replace(/<\/p>/g, '')
                        .replace(/<br\s*\/?>/g, '')
                        .replace(/amp;/g, '');
                    //console.log(latexExplain)
                    explain = katex.renderToString(latexExplain, {
                        throwOnError: false
                    });
                }
                this.question = {
                    explain,
                    title,
                    is_star: this.currentTypes[val].is_star,
                }
                //选择题处理选项
                if (typeof this.currentTypes[val].options_json === "string" && this.currentTypes[val].options_json.length > 0) {

                    this.question['options'] = JSON.parse(this.currentTypes[val].options_json)
                }
                let answerObj = {}
                //模考试卷通知后组织答案数据
                if (typeof this.currentType.answer_records !== "undefined") {

                    const answertmp = JSON.parse(this.currentType.answer_records.answer)
                    answerObj = {
                        answer: answertmp,
                        timeval: answertmp.timeval,
                    }
                } else {
                    //获取答案
                    answerObj = this.currentAnswerList.find(item => item.index == this.currentIndex)
                }
                if (typeof answerObj !== "undefined") {
                    this.answer = answerObj.answer.answer//文本
                    this.currentSelArr = answerObj.answer.answer//数组
                    this.timeval = answerObj.answer.timeval ? answerObj.answer.timeval : 0//时间
                    //模拟试卷加上 自测评分
                    if (this.currentType.type == 2) {
                        this.selfScore = answerObj.answer.selfScore ? answerObj.answer.selfScore : 0
                    }
                    if (Array.isArray(answerObj.answer.url) && answerObj.answer.url.length > 0) {
                        //是否有图片
                        this.fileList = answerObj.answer.url
                    }
                } else {
                    //翻译题 图片和文本
                    if (this.currentType.is_new == 3) {
                        //清空答案和时间
                        this.answer = [
                            "", "", "", "", ""
                        ]
                        this.fileList = [
                            [],
                            [],
                            [],
                            [],
                            []
                        ]
                    } else {
                        //清空答案和时间
                        this.answer = ""
                        this.fileList = []
                    }

                    //完形填空 阅读理解 新题型 单独处理
                    this.currentSelArr = []
                    this.currentChoose = 0;
                    this.timeval = 0
                    this.selfScore = 0
                }
            },
            immediate: true,
            deep: true
        }
    }
}