export default{
	namespaced: true,
	state:{
		isInMiddle:false,
	},
	mutations:{
		setIsInMiddle(state, payload){
			state.isInMiddle = payload
		}
	}
}