<template>
	<div class="container">
		<div class="list" v-for="(item, i) in list" :key="i">
			<div class="course-item" :style='{marginBottom:i==list.length-1?"0":"30px"}'>
				<div class="left">
					<div class="ball">
					</div>
					<div class="title">
							{{item.fileName}}
					</div>
				</div>
				<div class="btn" @click="toVideo(item)">
					进入课堂
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name:"enter_course",
		data() {
			return {
				
			};
		},
		methods:{
			toVideo(item){
				uni.navigateTo({
					url:`/subpkg/play_knowledge/play_knowledge?url=${item.remote_url}`
				})
			}
		},
		props:{
			list:{
				type:Array,
				default:[
					{
						fileName:'实时时钟、CMOSRAM和'
					},
				]
			}
		}
	}
</script>

<style lang="less" scoped>
.container{
	.list{
		.course-item{
			background-color: #fff;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.left{
				width: 70%;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				.ball{
					width: 10px !important;
					height: 10px !important;
					border-radius: 50%;
					background: #CDF3E7;
				}
				.title{
					width: 200px;
					font-size: 14px;
					color: #4A4A4C;
					font-weight: bold;
					margin-left: 5px;
					text-align: center;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}
			.btn{
				width: 65px;
				height: 22px;
				background: linear-gradient( 90deg, #10C19D 0%, #16AC8E 100%);
				border-radius: 18px;
				font-size: 12px;
				color: #fff;
				text-align: center;
				line-height: 22px;
			}
		}
	}
}
</style>