<template>
	<div class="container">
		<div class="all-course-h1" :style="{backgroundColor:bgcolor}">
			<div class="green-block-big">
				
			</div>
			<span>{{title}}</span>
		</div>
	</div>
</template>

<script>
	export default {
		name:"user_title",
		data() {
			return {
				
			};
		},
		props:['title', 'bgcolor']
	}
</script>

<style lang="less" scoped>
.container{
	.all-course-h1 {
		background-color: #F6F7FB;
		padding: 8px 0;
		padding-top: 0;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	
		.green-block-big {
	
			width: 9px;
			height: 19px;
			background: #01997A;
			border-radius: 0px 10px 0px 10px;
	
		}
	
		span {
			padding-left: 5px;
			height: 22px;
			font-weight: bold;
			font-size: 16px;
			color: #1F1F27;
		}
	}
}
</style>