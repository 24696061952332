const USERINFO = 'userInfo'
export function setUserInfo(userInfo) {
    localStorage.setItem(USERINFO, JSON.stringify(userInfo))
}
export function getUserInfo() {
    let userInfo = localStorage.getItem(USERINFO) || '{}';
    return JSON.parse(userInfo)

}
export function removeUserInfo(userInfo) {
    localStorage.removeItem(USERINFO);
}