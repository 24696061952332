//试题与模板答案的对应关系
export const answerTemplate = {
	//简答题模板适用于 英语大小作文，政治分析题
	SHORT: "/subpkg/p_answer_analysis/p_answer_analysis",
	//选择题模板适用于 所有单选题
	SINGLE: "/subpkg/p_single_choce_answer/p_single_choce_answer",
	//多选题模板适用于 所有多选题
	MULTI: "/subpkg/politics_multiple_choice_b/politics_multiple_choice_b",
	//完形填空模板适用于 英语完形填空
	MATERIAL: "/english/material_answer/material_answer",
	//翻译模板
	TRANSLATE: "/english/translate_answer/translate_answer",
}
//客观题列表
export const subjectTypeArr = ['JUDGE', 'SINGLE', 'MULTI', 'MATERIAL']
//主观题列表
//export const subjectiveTypes = ['SHORT','FILL']
//上传配置
export const uploadConfig = {
	maxSingleSize: 1024 * 1024 * 5, // 5MB
	Bucket: 'yanqu-online-mini-1300870289', /* 填写自己的 bucket，必须字段 */
	Region: 'ap-nanjing'
}
export const whiteRouterList = ['correction']