<template>
    <div class="container">
        <van-empty style="margin-top: 20px; font-size: 24px;" description="空页面" />
    </div>
</template>

<script>
export default  {
    data(){
        return{

        }
    }
}
</script>

<style lang="less" scoped>
.container{
    display: flex;
    justify-content: center;
}
</style>