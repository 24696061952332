import COS from "cos-js-sdk-v5"
import { http } from "@/utils/http.js"
const cos = new COS({
    SimpleUploadMethod: 'putObject', // 强烈建议，高级上传、批量上传内部对小文件做简单上传时使用 putObject,SDK 版本至少需要v1.3.0
    // 必选参数
    getAuthorization: function (options, callback) {
        // 服务端获取签名，请参考对应语言的 COS SDK：https://cloud.tencent.com/document/product/436/6474
        // 注意：这种有安全风险，后端需要通过 method、pathname 严格控制好权限，例如不允许 put / 等

        http.get("/api/get_sts/getKey").then(res => {
            const data = res.data;
            const credentials = res.data.credentials
            if (!data || !credentials) return console.error('credentials invalid');
            callback({
                TmpSecretId: credentials.tmpSecretId,
                TmpSecretKey: credentials.tmpSecretKey,
                // v1.2.0之前版本的 SDK 使用 XCosSecurityToken 而不是 SecurityToken
                SecurityToken: credentials.sessionToken,
                // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
                StartTime: data.startTime, // 时间戳，单位秒，如：1580000000
                ExpiredTime: data.expiredTime, // 时间戳，单位秒，如：1580000900
                ScopeLimit: true, // 细粒度控制权限需要设为 true，会限制密钥只在相同请求时重复使用
            });
        })
    },

});
export default cos;