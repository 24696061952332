<template>
    <div>
    <div class="right">
        <div @click="showFeedBack">
            <van-icon size="25" name="close" />
            <span>反馈</span>
        </div>
        <div @click="toggleStar" v-if="typeof currentTypes[currentIndex] !=='undefined'&&currentTypes[currentIndex].is_star==true">
            <van-icon size="25" name="star" color="#009c7b"/>
            <span>收藏</span>
        </div>
        <div @click="toggleStar" v-else>
            <van-icon size="25" name="star-o"/>
            <span>收藏</span>
        </div>
        <div @click="answerSheet">
            <van-icon size="25" name="records-o" />
            <span>答题卡</span>
        </div>
    </div>
    <van-popup v-model:show="show" position="bottom" :style="{height: '35%'}">
        <div class="feedback-container">
            <div>
                <span>反馈</span>
            </div>
                <van-field
                    style="margin-top: 17px; font-size: 14px;height: 100px;"
                    v-model="feedbackValue"
                    rows="8"
                    autosize
                    :border='false'
                    type="textarea"
                    placeholder="请输入反馈内容"
                    class="content">
                </van-field>
                <van-button class="submit-btn" @click="submit" color="#009c7b" block>提交</van-button>
        </div>
    </van-popup>
    </div>
</template>
<script>
import{mapState} from "vuex"
import { Toast } from 'vant';
import { addFeedBack, starToggle } from "@/api/professional/index.js";
const showToast = Toast
export default {
    data() {
        return {
            show: false,
            feedbackValue:''
        }
    },
    methods: {
        showFeedBack() {
            this.show = true;
        },
        async submit() {
            if (this.feedbackValue == "") {
                showToast("反馈内容不能为空");
                return;
            }
            const data = {
                qid: this.currentTypes[this.currentIndex].id,
                content: this.feedbackValue
            };
            const ret = await addFeedBack(data);
            if (ret !== false) {
                showToast("添加成功");
                this.show = false;
            }
        },
        async toggleStar() {
            let data = {
                qid: this.currentTypes[this.currentIndex].id,
                pqid:this.currentTypes[this.currentIndex].pqid
            };
            let ret = await starToggle(data);
            if (ret !== false) {
                showToast(ret.msg);
                this.$store.commit('professionalConfig/changeCurrentTypesByCurrentIndex', { is_star: ret.data.add });
            }
        },
        answerSheet() {
            this.$parent.saveCurrentAnswer()
            this.$router.push({ name: "answerSheet" });
        }
    },
    computed: {
        ...mapState("professionalConfig", ['currentTypes', "currentIndex"])
    }
}
</script>


<style lang="less" scoped>
    .right {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: #777777;
        font-size: 9px; 
        div {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 5px; 
        }
    }
    .content {
        height: 60px; 
    }
    .submit-btn {}

    .feedback-container {
        background-color: rgb(247, 248, 250);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        font-size: 14px; 
        padding: 10px; 
        .submit-btn {
            border-radius: 10px; 
            height: 30px; 
            font-size: 10px; 
            margin-top: 20px; 
        }
    }
</style>
