<template>
    <div class="container">
        <div class="head">
            <div class="left">
                <span>{{ currentIndex - 0 + 1 }}</span>
                <span>/{{ currentTypes.length }}</span>
                <span style="margin-left: 40px;">{{ goneTime}}</span>
            </div>
            <headopt></headopt>
        </div>

        <div class="type">单选题</div>
        <div class="question-title" v-html="question.title"></div>
        <div class="question-option">
            <div class="option-item" v-for="(item, key) in question.options" :key="key" @click="answer = key">
                <span class="option-name" :class="{ sel: answer == key }">{{ key }}</span>
                <div v-html="question.options[key]"></div>
            </div>
        </div>
        <div class="btn-container">
            <div class="btn-prev" @click="prev" >上一题</div>
            <div class="btn-next" @click="next">下一题</div>
        </div>
    </div>
</template>

<script>
import headopt from "@/components/title_block/headopt.vue"
import mixins from "@/mixins/index.js"
import katex from 'katex'
export default {
    data() {
        return {
            answer: "",
            question: "", //具体问题
            questionOption: {}, //选项内容,
            title: '',
        }
    },
    mixins: [mixins],
    components: {
        headopt
    },
    methods: {
        //下一题
        next() {
            //保存答题结果
            let answer = {
                answer: this.answer,
                timeval: this.timeval,
            }

            const data = {
                answer: answer,
                index: this.currentIndex,//保存当前 题库的进度
            }
            //当前结果保存本地
            this.$store.commit("professionalConfig/setAnswerList", data);
            this.whetherToNext()
        }
    },
}
</script>

<style lang="less" scoped>
.container {
    position: relative;
    padding: 0 18px;
    min-height: 100vh;

    .head {
        padding-top: 6px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            span {
                color: #2D2D2D;
                font-size: 15px;
            }
        }
    }

    .type {
        width: 43px;
        height: 22.5px;
        background: #CDF3E7;
        border-radius: 6px;
        margin-top: 8px;
        font-weight: 400;
        font-size: 10px;
        color: #01997A;
        line-height: 22.5px;
        text-align: center;
    }

    .question-title {
        margin-top: 7.5px;
        max-width: 100%;
        word-wrap: break-word;
        overflow-wrap: break-word;
        white-space: normal;
        overflow-x: auto;

        .icon {
            width: 70px !important;
        }
    }

    .question-option {
        .option-item {
            .option-name {
                width: 37px;
                height: 37px;
                border: 1px solid #AFAFAF;
                border-radius: 50%;
                text-align: center;
                line-height: 37px;
                margin-right: 10px;
            }

            margin-top: 20px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }

        .sel {
            background-color: #01997A;
            color: #fff;
        }
    }

    .btn-container {
        width: calc(100vw - 36px);
        position: fixed;
        bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .btn-prev,
        .btn-next {
            border-radius: 8px;
            padding: 8px 23px;
            border: 1px solid #01997A;
        }

        .btn-prev {
            color: #01997A;
        }

        .btn-next {
            color: #fff;
            background-color: #01997A;
        }
    }
}
</style>
