<template>
    <div class="container">
      <div class="head">
          <div class="left">
                  <span>{{ currentIndex - 0 + 1 }}</span>
                  <span>/{{ currentTypes.length }}</span>
                  <span style="margin-left: 40px;">{{ goneTime}}</span>
              </div>
              <headopt></headopt>
          </div>
          <div class="type">简答题</div>
          <div class="question-title" v-html="question.title" ></div>
          <div class="answer">
            <h2>我的答案</h2>
            <div class="input">
              <van-field
                  v-model=" answer"
                  rows="1"
                  size="large"
                  autosize
                  type="textarea"
                  placeholder="请输入答案"
                />
            </div>
          </div>
  
          <div class="upload-file">
          <van-uploader v-model="fileList" :after-read="afterRead" preview-size="100" multiple>
  
          </van-uploader>
          </div>
  
          <div class="btn-container">
              <div class="btn-prev" @click="prev" >上一题</div>
              <div class="btn-next" @click="next">下一题</div>
          </div>
    </div>
  </template>
  
  <script>
  
  import headopt from "@/components/title_block/headopt.vue"
  import mixins from "@/mixins/index.js"
  import {uploadFile} from "@/api/professional/index"
  export default {
      data() {
          return {
              answer: "",
              question: "", //具体问题
              questionOption: {}, //选项内容,
              title: '',
              fileList:[
  
              ]
          }
      },
      mixins: [mixins],
      components: {
          headopt
      },
      methods: {
          //下一题
          next() {
              //保存答题结果
              let answer = {
                  answer: this.answer,
                  url: this.fileList,
                  timeval: this.timeval,
              }
  
              const data = {
                  answer: answer,
                  index: this.currentIndex,//保存当前 题库的进度
              }
              //当前结果保存本地
              this.$store.commit("professionalConfig/setAnswerList", data);

              this.whetherToNext()
          },
          async afterRead(file){
              console.log(this.fileList)
              const formData = new FormData();
              try{
                  file.status = 'uploading';
                  file.message = '上传中...';
                  formData.append('file', file.file);
                  let result =  await uploadFile(formData)
       
                  this.fileList.push({
                      url:result.fullurl
                  })
                  file.status = 'done';
                  file.message = '上传成功';
              }catch(e){
                  console.log(e)
                  file.status = 'failed';
                  file.message = '上传失败';
              }
              this.fileList = this.fileList.filter(item => typeof item.url!== 'undefined')
          }
      },
  }
  </script>
  
  <style lang="less" scoped>
  .container {
      .container {
    position: relative;
      padding: 0 18px;
      min-height: 100vh;
      .head{
          padding-top: 6px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .left{
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              span{
                  color: #2D2D2D;
                  font-size: 15px;
              }
          }
          .right{
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #777777;
              font-size: 9px;
              div{
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-left: 5px;
              }
          }
      }
      .type{
          width: 43px;
          height: 22.5px;
          background: #CDF3E7;
          border-radius: 6px;
          margin-top: 8px;
          font-weight: 400;
          font-size: 10px;
          color: #01997A;
          line-height: 22.5px;
          text-align: center;
      }
      
      .question-title{
          margin-top: 7.5px;
          width: 100%;
          overflow-y: scroll;
          .icon{
              width: 70px !important;
          }
      }
      .answer{
          margin-top: 7.5px;
          h2{
              font-size: 14px;
              font-weight: 400;
              color: #777777;
          }
          .input{
            margin-top: 6px;
            min-height: 116.5px;
            background: #FFFFFF;
            border-radius: 11px;
            border: 1px solid #707070;
            padding: 10px;
          }
          ::v-deep .van-field__control{
              height: 15px;
              line-height: 15px;
              font-size: 16px;
          }
      }
      .upload-file{
        margin-top: 20px;
        ::v-deep .van-uploader__upload-icon {
          font-size: 35px; /* Modify icon size to 30px */
        }
      }
      .btn-container{
          width: calc(100vw - 36px);
          position: fixed;
          bottom: 5px;
          
          display: flex;
          align-items: center;
          justify-content: space-between;
          .btn-prev, .btn-next{
              border-radius: 8px;
              padding: 8px 23px;
              border: 1px solid #01997A;
          }
          .btn-prev{
              color: #01997A;
          }
          .btn-next{
            color: #fff;
            background-color: #01997A;
          }
      }
  }
  }
  </style>