import { getUserInfo, setUserInfo, removeUserInfo } from "@/utils/storage.js"
let user = {
    namespaced: true,
    state: {
        userInfo: getUserInfo()
    },
    mutations: {
        setUser(state, payload) {
            state.userInfo = { ...state.userInfo, ...payload }
            setUserInfo(payload)
        },
        removeUser(state, payload) {
            state.userInfo = {}
            removeUserInfo()
        },
    },
    actions: {
        async login(context) {
            let that = this;
            //获取用户信息
            const loginInfo = await uni.login({
                provider: "weixin"
            });
            let rest = await uni.$u.http.get("/mini_user/wxLogin", {
                params: {
                    code: loginInfo[1].code
                }
            })
            let ret = rest.data
            if (ret.token) {
                let rets = {
                    token: ret.token,
                    phone: ret.phone,
                    nickName: ret.nickName,
                    avatarUrl: ret.avatarUrl
                }
                context.commit('setUser', rets)
            }

        }
    }

}
export default user