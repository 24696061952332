const origin = {
    namespaced: true,
    state: {
        originName: ""
    },
    mutations: {
        setOriginName(state, payload) {
            state.originName = payload
        }
    }
}
export default origin