var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"container-top"},[_c('div',{staticClass:"head"},[_vm._m(0),_c('div',{staticClass:"right"},[_c('div',[_c('van-icon',{attrs:{"size":"30","name":"close"}}),_c('span',[_vm._v("反馈")])],1),_c('div',[_c('van-icon',{attrs:{"size":"30","name":"star-o"}}),_c('span',[_vm._v("收藏")])],1),_c('div',[_c('van-icon',{attrs:{"size":"30","name":"records-o"}}),_c('span',[_vm._v("答题卡")])],1)])]),_c('div',{staticClass:"type"},[_vm._v("解答题")]),_c('div',{staticClass:"answer"},[_c('div',{staticClass:"question-title",domProps:{"innerHTML":_vm._s(_setup.answer.question)}}),_c('h2',[_vm._v("我的答案")]),_c('div',{staticClass:"input"},[_c('van-field',{attrs:{"rows":"1","size":"large","autosize":"","type":"textarea","placeholder":"请输入答案"},model:{value:(_setup.answer.msg),callback:function ($$v) {_vm.$set(_setup.answer, "msg", $$v)},expression:"answer.msg"}})],1)]),_c('van-image',{staticClass:"answer-img",attrs:{"src":"https://fastly.jsdelivr.net/npm/@vant/assets/cat.jpeg"}})],1),_c('div',{staticClass:"title-block"},[_vm._m(1),_c('div',{staticClass:"content",domProps:{"innerHTML":_vm._s(_setup.answer.rightAnswer)}})]),_c('div',{staticClass:"title-block"},[_vm._m(2),_c('div',{staticClass:"content",domProps:{"innerHTML":_vm._s(_setup.answer.answerDetail)}})]),_c('div',{staticClass:"title-block"},[_vm._m(3),_c('div',{staticClass:"input"},[_vm._v(" 这是点评 ")]),_c('van-image',{staticClass:"comment-img",attrs:{"src":"https://cdn.uviewui.com/uview/swiper/2.jpg"}}),_vm._m(4)],1),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"left"},[_c('span',[_vm._v("1/20")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"top"},[_c('span',{staticClass:"green-block"}),_c('span',{staticClass:"title"},[_vm._v("正确答案")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"top"},[_c('span',{staticClass:"green-block"}),_c('span',{staticClass:"title"},[_vm._v("答案解析")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"top"},[_c('span',{staticClass:"green-block"}),_c('span',{staticClass:"title"},[_vm._v("老师点评")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"self-rate"},[_vm._v(" 本题老师评分数 "),_c('span',[_vm._v("8分")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"title-block"},[_c('div',{staticClass:"top"},[_c('span',{staticClass:"green-block"}),_c('span',{staticClass:"title"},[_vm._v("本题自评分数")])]),_c('div',{staticClass:"self-rate"},[_vm._v(" 自测评分80 ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"title-block"},[_c('div',{staticClass:"top"},[_c('span',{staticClass:"green-block"}),_c('span',{staticClass:"title"},[_vm._v("知识点")])]),_c('div',{staticClass:"knowledge-list"},[_c('span',[_vm._v("函数的基本定理")]),_c('span',[_vm._v("函数的基本定理")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"title-block"},[_c('div',{staticClass:"top"},[_c('span',{staticClass:"green-block"}),_c('span',{staticClass:"title"},[_vm._v("精准补漏")])]),_c('div',{staticClass:"vedio-list"},[_c('div',{staticClass:"vedio"},[_c('div',{staticClass:"left"},[_c('span',{staticClass:"green-ball"}),_c('span',{staticClass:"vedio-title"},[_vm._v("考点精讲1-函数基础知识")])]),_c('div',{staticClass:"right"},[_c('button',{staticClass:"reset-btn btn-enter"},[_vm._v("进入课堂")])])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"btn-container"},[_c('div',{staticClass:"btn-prev"},[_vm._v("上一题")]),_c('div',{staticClass:"btn-next"},[_vm._v("下一题")])])
}]

export { render, staticRenderFns }