<template>
	<div class="container">
		<div class="item">
			<div class="top-text">
				<span>{{item.usingTime}}</span>
				<span>秒</span>
			</div>
			<span>答题用时</span>
		</div>
		<div class="item van-hairline--left">
			<div class="top-text">
				<span>{{item.rightRate}}</span>
				<span>%</span>
			</div>
			<span>全站正确率</span>
		</div>
		<div class="item van-hairline--left">
			<div class="top-text">
				<span>{{item.easyWrongOption}}</span>
				<span></span>
			</div>
			<span>易错项</span>
		</div>
	</div>
</template>

<script>
	export default {
		name:"question_board",
		data() {
			return {
				
			};
		},
		props:{
			item:{
				type:Object,
				default:{
					usingTime:130,
					rightRate:70,
					easyWrongOption:"abc"
				}
			}
		}
	}
</script>

<style lang="less" scoped>
.container{
	background: #F6F7FB;
	border-radius: 9px;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 65px;
	.item{
		text-align: center;
		flex: 1;
		color: #777;
		font-size: 9px;
		.top-text{
			span{
			font-size: 16px;
			color: #201E2E;
			&:last-child{
				color: #777;
				font-size: 9px;
			}
			}
		}
	}
}
</style>