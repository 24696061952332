<template>
    <div class="container">
        <div class="content">
            <div class="top-opt">
                <div class="progress-text">
                    <span>{{ currentIndex - 0 + 1 }}</span>
                    <span>/{{ currentTypes.length }}</span>
                </div>
                <headopt></headopt>
            </div>
            <div class="q-type">
                填空题
            </div>
            <!-- 题干部分 -->
            <div class="q-title q-text">
                <div v-html="question.title"></div>
            </div>
        </div>
        <!-- 标题 -->
        <div class="q-answer">
            <div class="title">
                <user-title :title='"参考答案"' :bgcolor="'#fff'"></user-title>
            </div>
            <div class="sys-answer" v-html="sysAnswer"></div>

            <h2>我的答案</h2>
            <div class="input">
                <van-field :border="true" readonly v-model="answer" rows="1" size="large" autosize type="textarea" placeholder="请输入答案" />
            </div>
            <div class="img-container" v-for="item in fileList" :key="item.url">
            <van-image :src="item.url" style="width: 100%;"/>
        </div>
        </div>
        
        <!-- 解析 -->
        <div class="analysis">
            <div class="title">
                <user-title :title='"答案解析"' :bgcolor="'#fff'"></user-title>
            </div>
            <div class="answer-info">
                <div v-html="question.explain"></div>
            </div>
        </div>

        

        	<!-- 	老师点评 -->
		<div class="comment" v-if="currentType.answer_records">
			<div class="comment-title">
				<user-title :title='"老师点评"' :bgcolor="'#fff'"></user-title>
				<div class="a-content" v-if="currentType.answer_records.marking_comment">
					<span>{{currentType.answer_records.marking_comment}}</span>
				</div>
			</div>
			<div class="img-list" v-if="currentType.answer_records.marking_pic">
				<user-title :title='"老师批改"' :bgcolor="'#fff'"></user-title>
				<img style="width: 100%;" :src="currentType.answer_records.marking_pic"></img>
			</div>

			<div class="edit-score" style="margin-top: 46rpx;" v-if="currentType.answer_records.marking_score">
				<user-title :title='"老师打分"' :bgcolor="'#fff'"></user-title>
                <div class="a-content" v-if="currentType.answer_records.marking_comment">
					<span>{{currentType.answer_records.marking_score}}</span>
				</div>
            </div>
		</div>

		<!-- 试卷显示自测评分 -->
		<div class="edit-score" v-if="currentType.type==2">
			<div class="title">
				<user-title :title='"自测评分(满分"+currentType.score+")"' :bgcolor="'#fff'"></user-title>
			</div>
			<div class="self-score">
                <van-field
                    style="height: 20px;"
                    v-model="selfScore"
                    :readonly="correctionCompleted==1? true:false"
                    placeholder="请输入分数"
                />
			</div>
		</div>

        <!-- 知识点 -->
        <div class="knowledge-points" v-if="Array.isArray(currentType.knowledgeInfo) && currentType.knowledgeInfo.length > 0">
            <div class="title">
                <user-title :title='"知识点"' :bgcolor="'#fff'"></user-title>
            </div>

            <div class="point">
                <div class="point-tag" v-for="(item, index) in currentType.knowledgeInfo" :key="index">
                    {{ item.knowledge_name }}
                </div>
            </div>
        </div>
        <!-- 补漏 -->
        <div class="traps" v-if="Array.isArray(currentType.knowledgeInfo) && currentType.knowledgeInfo.length > 0">
            <div class="title">
                <user-title :title='"精准补漏"' :bgcolor="'#fff'"></user-title>
            </div>
            <enter-course :list="currentType.knowledgeInfo"></enter-course>
        </div>

        <!-- 来源 -->
        <div class="knowledge-origin" v-if="currentType.origin">
            <div class="title">
                <user-title :title='"来源"' :bgcolor="'#fff'"></user-title>
            </div>
            <div class="knowledge-origin-title">
                {{ currentType.origin }}
            </div>
        </div>
        <div class="bottom-btn">
            <div class="prev" @click="answerPrev">
                上一题
            </div>
            <div class="next" @click="answerNext">
                下一题
            </div>
        </div>
    </div>
</template>

<script>
import mixins from "@/mixins/index.js"
import questionBoard from "@/components/question_board/question_board.vue"
import headopt from "@/components/title_block/headopt.vue"
import userTitle from "@/components/user_title/user_title.vue"
import enterCourse from "@/components/enter_course/enter_course.vue"
import katex from 'katex'
export default {
    data() {
        return {
            safeTopHeight: 0,
            answer: "",
            question: "",//具体问题
            questionOption: {},//选项内容
        };
    },
    mixins: [mixins],
    components: {
        questionBoard,
        headopt,
        userTitle,
        enterCourse
    },
    mounted() {
        if (this.timerId != null) {
            clearInterval(this.timerId)
            this.timerId = null
        }
    },
    computed: {
        sysAnswer() {
            let answer = ""
            try{
                answer = JSON.parse(this.currentType.answer);
            }catch(e){
                answer = this.currentType.answer
            }
            let latex = "";
            if (typeof answer === 'string') {
                latex = answer.replace(/<p>/g, '').replace(/<\/p>/g, '').replace(/<br\s*\/?>/g, '');
            } else if (typeof answer === 'Array') {
                latex = answer[0].answers[0].replace(/<p>/g, '').replace(/<\/p>/g, '').replace(/<br\s*\/?>/g, '');
            }

            const title = katex.renderToString(latex, {
                throwOnError: false
            });
            return title
        }
    }
}
</script>
<style lang="less" scoped>
.input ::v-deep .van-cell{
    margin-top: 10px;
    min-height: 120px;
    border:1px solid #ccc;
}
.self-score  ::v-deep .van-cell{
    margin-top: 10px;
    min-height: 50px;
    border:1px solid #ccc;
}
.container {
    h2{
            margin-top: 10px;
            font-size: 14px;
            font-weight: 400;
            color: #777777;
        }
    .img-container {
        margin-top: 10px
    }
    background-color: #F6F7FB;

    .center {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #2D2D2D;
        font-size: 16px;
    }

    .content {
        background-color: #fff;
        padding: 0 18px;
        padding-bottom: 40px;

        .top-opt {
            width: 100%;
            padding: 5px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .progress-text {
                text {
                    color: #777777;
                    font-size: 13px;

                    &:first-child {
                        font-size: 17px;
                        color: #2D2D2D;
                        font-weight: bold;
                    }
                }
            }

            .opt {
                display: flex;
                align-items: center;
                justify-content: space-between;

                div {
                    margin-left: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    text {
                        font-size: 9px;
                        color: #777;
                    }
                }
            }
        }

        .q-type {
            margin-top: 6px;
            background-color: #CDF3E7;
            border-radius: 6px;
            color: #009c7b;
            font-size: 12px;
            width: 43px;
            height: 23px;
            text-align: center;
            line-height: 23px;
        }

        .q-text {

            line-height: 25px;
            color: #5A5A5A;
            font-size: 14px;
        }

        .q-title {
            margin-top: 18px;
            margin-bottom: 25px;
        }

        .q-sel-list {

            .q-item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 20px;

                span {
                    width: 295px;
                }

                .q-check-normal {
                    width: 35px;
                    height: 35px;
                    border: 1px solid #AFAFAF;
                    color: #5A5A5A;
                    background-color: #fff;
                    border-radius: 50%;
                    line-height: 35px;
                    text-align: center;
                }

                .q-check-sel {
                    background: #01997A;
                    border: 0;
                    color: #fff;

                }
            }
        }

    }

    .q-answer,
    .analysis,
    .knowledge-points,
    .traps,
    .knowledge-origin,
    .comment-title,
    .img-list,
    .edit-score {
        padding: 0 18px;
        margin-top: 8px;
        background-color: #fff;
        padding-bottom: 15px;
        padding-top: 15px;
    }

    .q-answer-info {
        margin-bottom: 15px;

        span {
            font-size: 14px;
            color: #5A5A5A;
            font-weight: bold;
            letter-spacing: 2px;

            &:nth-child(2) {
                margin-left: 5px;
                color: #009c7b;
            }

            &:last-child {
                margin-left: 5px;
                color: rgb(206, 97, 117);
            }
        }

        .sys-answer {
            margin-top: 12px
        }

    }

    .analysis {
        .answer-info {
            width: 100%;
            overflow-y: scroll;
            color: #5A5A5A;
            font-size: 14px;
        }
    }

    .knowledge-points {
        .point {
            padding-top: 5px;
            display: flex;
            flex-wrap: wrap;
            align-content: flex-start;
            justify-content: flex-start;

            .point-tag {
                margin-left: 5px;
                margin-top: 5px;
                padding: 4px 9px;
                border: 2px solid #009c7b;
                border-radius: 8px;
                color: #009c7b;
                font-size: 14px;
                font-weight: bold;
            }
        }
    }

    .knowledge-origin {
        .knowledge-origin-title {
            color: #5A5A5A;
            font-size: 14px;
        }


    }
    .comment {
			margin-top: 16px;
			background-color: #fff;
			padding-bottom: 60px;

			.qanswer {
				font-size: 28px;
				color: #5A5A5A;
			}

		}
    .bottom-btn {

        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 28px;
        padding: 18px;
        background-color: #fff;

        div {
            width: 85px;
            height: 35px;
            border-radius: 8px;
            border: 1px solid #01997A;
            line-height: 35px;
            text-align: center;
            font-size: 14px;
        }

        .prev {
            background: #FFFFFF;
            color: #009c7b;
        }

        .next {
            background-color: #009c7b;
            color: #fff;
        }
    }
}
</style>
